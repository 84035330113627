import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import siteConfigs from "../../constants/StieConfigs";
import { FiMail, FiPhone } from "react-icons/fi";
class Footer extends Component {
  render() {
    const {t} = this.props
    return (
      <footer className="footer-area" id="contact">
        {/* Start Footer TOp */}
        <div className="footer-top ptb--80">
          <div className="container">
            <div className="row">
              {/* Start Single Widget */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                  <div>
                  <img
                        src={`${process.env.PUBLIC_URL}/assets/images/hrplus/Logo 2.png`}
                        alt="App landing"
                      />
                  </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget address">
                  <div className="inner inner-custom-hr">
                    <div>
                      <p>{ t('common:FOOTER.COMPANY_NAME') }</p>
                    </div>
                    <div className="addres">
                      <p>{ t('common:FOOTER.ADDRESSONE') }</p>
                      <p>
                        <p>
                        { t('common:FOOTER.ADDRESSTWO') }
                        </p>
                        <p>{ t('common:FOOTER.ADDRESSTHREE') }</p>
                        <p>{ t('common:FOOTER.ADDRESSFOUR') }</p>
                        {/* <a className="link-hover" href="/">
                          contact@aeroland.com
                        </a> */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget address">
                  <div className="inner inner-custom-hr">
                    <div>
                      <p>{ t('common:FOOTER.CONTACT') }</p>
                    </div>
                    <div className="addres">
                      <p>{ t('common:FOOTER.PHONE') }</p>
                      <p>
                        <p>
                        <a className="d-flex align-items-center gap-5" href="tel:+6622453936"><div className="d-flex align-items-center"><FiPhone /></div>+66 (2) 245 3936</a>
                        </p>
                        <p><a className="d-flex align-items-center gap-5" href="tel:+66631918474"><div className="d-flex align-items-center"><FiPhone /></div>+66 63 191 8474</a></p>
                        <p><a className="d-flex align-items-center gap-5" href="tel:+66818234883"><div className="d-flex align-items-center"><FiPhone /></div>+66  81 823 4883</a></p>
                        <p>{ t('common:FOOTER.EMAILTITLE') }</p>
                        <p><a href="mailto:kamonruji@perumaltt.com" className="d-flex align-items-center gap-5"><div className="d-flex align-items-center"><FiMail /></div>kamonruji@perumaltt.com</a></p>
                        {/* <a className="link-hover" href="/">
                          contact@aeroland.com
                        </a> */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget address">
                  <div className="inner inner-custom-hr">
                    <div>
                      <p>{ t('common:FOOTER.DOWNLOAD') }</p>
                    </div>
                    <div className="addres">
                        <a href={siteConfigs.playStoreLink} target="_blank">
                          <button className="download-clickable">
                          <img src="/assets/images/hrplus/For Android.png" />
                          </button>
                        </a>
                        <a href={siteConfigs.appleStoreLink} target="_blank">
                          <button className="download-clickable mt-3">
                          <img src="/assets/images/hrplus/For iOS.png" />
                          </button>
                        </a>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget address">
                  <div className="inner inner-custom-hr">
                    <div>
                      <p>{ t('common:FOOTER.FOLLOW') }</p>
                    </div>
                    <div className="footer-social">
                        <a href="https://www.facebook.com/HR-Plus-ระบบจัดการเวลาทำงานและสวัสดิการพนักงาน-115455947835268/" target="_blank">
                          <img src="/assets/images/hrplus/Facebook.png" />
                        </a>
                        <p className="ml-3">
                          <img src="/assets/images/hrplus/Youtube.png" />
                        </p>
                    </div>
                  </div>
                </div>
              </div>
              
              
            </div>
          </div>
        </div>
        {/* End Footer TOp */}

        {/* Start Copyright Area */}
        <div className="copyright-area copyright-style--1">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="copyright text-center text-md-left">
                  <p>©{new Date().getFullYear()} HR Plus. All Rights Reserved.</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12  d-flex flex-row justify-content-between">
                <div className="copyright-right text-center text-md-right mt_sm--20">
                  <div className="social-share justify-content-end">
                   <a href="/#/privacy" target="_blank">Terms of Service and Privacy Policy</a>
                  </div>
                </div>
                <div className="copyright-right text-center text-md-right mt_sm--20">
                  <div className="social-share justify-content-end">
                   <a href="/#/cancellation" target="_blank">Cancellation Policy</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Copyright Area */}
      </footer>
    );
  }
}

export default withTranslation()(Footer);
