import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Slider from "react-slick";
import { tabSlide1, tabSlide2 } from "../../../Home/script";

class TabsTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.setState({
      nav1: this.tabSlide1,
      nav2: this.tabSlide2,
    });
  }
  render() {
    const { t } = this.props;
    return (
      <div className="tab-style--2 ptb--40">
        <div style={{paddingLeft: 100, paddingRight: 100}}>
            <div className="row">
            <div className="col-12">
            <div className="section-title text-center mb--55"><h3 className="heading fontWeight400 mb--20 custom-text-black">{ t('common:ADMIN.TITLE') }</h3></div>
            </div>
                
            </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="tab-content-wrapper">
                <Slider
                  {...tabSlide1}
                  asNavFor={this.state.nav2}
                  ref={(slider) => (this.tabSlide1 = slider)}
                  className="testimonial-image-slider text-center"
                >
                  <div className="tab2-content">
                    <div className="title">
                      {/* <i className="fa fa-align-left"></i> */}
                      <img src="assets/images/hrplus/Dashboard.png" />
                      <h4>{ t('common:ADMIN.DASHBOARD') }</h4>
                    </div>
                    <p>
                    { t('common:ADMIN.DASHBOARD_CONTENT') }
                    </p>
                  </div>

                  <div className="tab2-content mt--40">
                    <div className="title">
                      {/* <i className="fa fa-rocket"></i> */}
                      <img src="assets/images/hrplus/User Management.png" />
                      <h4>{ t('common:ADMIN.USER') }</h4>
                    </div>
                    <p>
                    { t('common:ADMIN.LEAVE_CONTENT') }
                    </p>
                  </div>

                  <div className="tab2-content mt--40">
                    <div className="title">
                      {/* <i className="fa fa-chart-pie"></i> */}
                      <img src="assets/images/hrplus/Leave Management.png" />
                      <h4>{ t('common:ADMIN.LEAVE') }</h4>
                    </div>
                    <p>
                    { t('common:ADMIN.USER_CONTENT') }
                    </p>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="col-lg-8 mt_md--40 mt_sm--40">
              <div className="tab2-thumbnail-wrapper slick-dots-style">
                <Slider
                  {...tabSlide2}
                  asNavFor={this.state.nav1}
                  ref={(slider) => (this.tabSlide2 = slider)}
                  className="testimonial-text-slider text-center"
                >
                  <div className="thumbnail slick-thumb-custom">
                    <div>
                      <img
                        src="/assets/images/hrplus/overview/Dashboard.png"
                        alt="app landing"
                      />
                    </div>
                  </div>

                  <div className="thumbnail slick-thumb-custom">
                    <div>
                      <img
                        src="/assets/images/hrplus/overview/User Management.png"
                        alt="app landing"
                      />
                    </div>
                  </div>

                  <div className="thumbnail slick-thumb-custom">
                    <div>
                      <img
                        src="/assets/images/hrplus/overview/Leave Management.png"
                        alt="app landing"
                      />
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(TabsTwo);
