// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch, Route, HashRouter} from 'react-router-dom';
import { Provider } from 'react-redux'
import './index.scss';
import store from "./store";
import HrPlus from './Home/Hrplus';
import PrivacyPolicy from './Home/privacy';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_th from "./translations/th/common.json";
import common_en from "./translations/en/common.json";
import CancellationPolicy from './Home/cancellation';
import CookieConsent from "react-cookie-consent";


i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
      en: {
        common: common_en               // 'common' is our custom namespace
      },
      th: {
        common: common_th
      },
    },
  });


class Root extends Component{
    render(){
        return(
            <>
              <Provider store={store}>
                  <I18nextProvider i18n={i18next}>
                      <HashRouter>
                          <Switch>
                              <Route path="/privacy" component={PrivacyPolicy}/>
                              <Route path="/cancellation" component={CancellationPolicy}/>
                              <Route path="/" component={HrPlus}/>
                          </Switch>
                      </HashRouter>
                  </I18nextProvider>
              </Provider>
              <CookieConsent
                    location="bottom"
                    buttonText="Accept!"
                    cookieName="myAwesomeCookieName2"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                    expires={150}
                    >
                    This website uses cookies to enhance the user experience.{" "}
                    </CookieConsent>
            </>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));



