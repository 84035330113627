import React, { Component } from "react";
import { FiX , FiMenu } from "react-icons/fi";
import Logo from "./LogoImage";
import { withTranslate } from 'react-redux-multilingual';
import { useTranslation } from "react-i18next";
function HeaderDefault(props){
    
    const {t, i18n} = useTranslation('common');
    // const [currentLang,setCurrentLang] = React.useState('EN')
    const {currentLang, onLangChange} = props

    const menuTrigger = () => {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    const CLoseMenuTrigger = () => {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    React.useEffect(() => {
        let lang = (i18n.language === 'en') ? "EN" : "TH"
        onLangChange(lang)
    }, [])
    const ChangeLang = (lang) => {
        console.log('lang',lang)
        i18n.changeLanguage(lang)
        localStorage.setItem('currentLang', lang)
        let langSelected = (lang === 'en') ? "EN" : "TH"
        onLangChange(langSelected)
      }

        return(
            <div>
                {/* style={{position: 'fixed', background: '#fff', height: 101}}  */}
                <header style={{ position: 'fixed',background: '#fff'}} className={`header ${props.headertransparent} ${props.colorblack}`}>
                    <div className="header-wrapper">
                        <div className="branding">
                            <div className="logo">
                                <Logo logo={props.logoname} />
                            </div>
                        </div>
                        <div className="page-navigation">
                            <nav className="mainmenu-nav">
                                <ul className="mainmenu">
                                    <li><a href="#head"><span>{t('MENU.HOME')}</span></a></li>
                                    <li><a href="#features"><span>{t('MENU.FEATURES')}</span></a></li>
                                    <li><a href="#pricing"><span>{t('MENU.PRICING')}</span></a></li>
                                    {/* <li><a href="#customers"><span>{t('MENU.HOME')}</span></a></li> */}
                                    <li><a href="#faq"><span>{t('MENU.FAQ')}</span></a></li>
                                    <li><a href="#contact"><span>{t('MENU.CONTACT')}</span></a></li>
                                    {/* <li className="has-dropdown"><a href="#home"><span>{t('MENU.HOME')}</span></a>
                                        <ul className="submenu">
                                            <li><a href="/branding">branding</a></li>
                                            <li><a href="/sass">sass</a></li>
                                            <li><a href="/app-showcase">app showcase</a></li>
                                            <li><a href="/software">software</a></li>
                                            <li><a href="/startups">startups</a></li>
                                            <li><a href="/payment">payment</a></li>
                                            <li><a href="/app-landing">app landing</a></li>
                                            <li><a href="/digital-agency">digital agency</a></li>
                                            <li><a href="/ebook">ebook</a></li>
                                            <li><a href="/clinic">clinic</a></li>
                                            <li><a href="/digital-store">digital store</a></li>
                                        </ul>
                                    </li>
                                    <li className="has-dropdown"><a href="#elements"><span>Elements</span></a>
                                        <ul className="submenu">
                                            <li><a href="/button">button</a></li>
                                            <li><a href="/accordion">accordion</a></li>
                                            <li><a href="/attribute-list">attribute list</a></li>
                                            <li><a href="/box-icon">box icon</a></li>
                                            <li><a href="/call-to-action">call to action</a></li>
                                            <li><a href="/dividers">dividers</a></li>
                                            <li><a href="/counters">counters</a></li>
                                            <li><a href="/gradation">gradation</a></li>
                                            <li><a href="/message-box">message box</a></li>
                                            <li><a href="/popup-video">popup video</a></li>
                                            <li><a href="/progress-bar">progress bar</a></li>
                                            <li><a href="/lists">lists</a></li>
                                            <li><a href="/social-share">social share</a></li>
                                            <li><a href="/gallery">gallery</a></li>
                                            <li><a href="/team">team</a></li>
                                            <li><a href="/pricing-box">pricing box</a></li>
                                            <li><a href="/tabs">tabs</a></li>
                                            <li><a href="/contact">contact</a></li>
                                            <li><a href="/testimonial">testimonial</a></li>
                                            <li><a href="/countdown">countdown</a></li>
                                        </ul>
                                    </li>
                                    <li className="has-dropdown"><a href="#blog"><span>Blog</span></a>
                                        <ul className="submenu">
                                            <li><a href="/blog-classic">blog classic</a></li>
                                            <li><a href="/blog-two-column">blog two column</a></li>
                                        </ul>
                                    </li>
                                    <li className="has-dropdown"><a href="#portfolio"><span>Portfolio</span></a>
                                        <ul className="submenu">
                                            <li><a href="/portfolio-grid">portfolio grid</a></li>
                                            <li><a href="/portfolio-grid-wide">portfolio grid wide</a></li>
                                            <li><a href="/portfolio-grid-caption">portfolio grid caption</a></li>
                                            <li><a href="/portfolio-custom-image">portfolio custom image</a></li>
                                            <li><a href="/portfolio-details">portfolio details</a></li>
                                        </ul>
                                    </li>
                                    <li className="has-dropdown"><a href="#pages"><span>Pages</span></a>
                                        <ul className="submenu">
                                            <li><a href="/contact">Contact Us</a></li>
                                            <li><a href="/not-found">404 Page</a></li>
                                            <li><a href="/maintenance">Maintenance</a></li>
                                        </ul>
                                    </li> */}
                                </ul>
                            </nav>
                        </div>
                        <div className="header-right">
                            <div className="header-btn-variant-2">
                                 <a href="https://auth.hrplusapp.com/#/auth/login" target="_blank">
                                    <button className="pr-1">Login</button>
                                </a>
                            </div>
                            <div className="header-btn-variant-1">
                            <a href="https://auth.hrplusapp.com/#/auth/register" target="_blank">
                                    <button className="pl-1">Sign up for free</button>
                                </a>
                            </div>
                            <div className="header-btn-variant-1">
                                {(currentLang == "EN") ?
                                <>
                                    <button onClick={() => ChangeLang('th')} className="pr-1 br-1">TH</button>
                                    <button onClick={() => ChangeLang('en')} className="pl-1 lang-active">EN</button>
                                </>
                                :
                                <>
                                    <button onClick={() => ChangeLang('th')} className="pr-1 br-1 lang-active">TH</button>
                                    <button onClick={() => ChangeLang('en')} className="pl-1">EN</button>
                                </>
                                
                                }
                            </div>
                            {/* <div className="header-btn-variant-1">
                                <button className="pr-1 br-1">Login</button>
                                <button className="pl-1">Sign up</button>
                            </div> */}
                            {/* <div className="header-btn-variant-2">
                                <a href="http://auth.hrplusapp.com/" target="_blank">
                                    <button>{t('MENU.GETSTARTED')}</button>
                                    </a>
                            </div> */}
                        </div>
                        </div>
                </header>
            </div>
        )
}

export default withTranslate(HeaderDefault);
