import React, { Component } from "react";
import Slider from "react-slick";
import {testimonial1} from "../../../Home/script";


class SassTestimonial extends Component{
    render(){
        let Data = [
            {
                thumb : '01',
                details: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ex, itaque repellendus? Optio soluta quisquam eligendi magni? Excepturi delectus quos sapiente harum reprehenderit.',
                title: 'Suwapat Amphan',
                subtitle: 'CEO',
                subtitle2: 'Ajinomoto'
            },
            {
                thumb : '02',
                details: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ex, itaque repellendus? Optio soluta quisquam eligendi magni? Excepturi delectus quos sapiente harum reprehenderit.',
                title: 'Suwapat Amphan',
                subtitle: 'CEO',
                subtitle2: 'CENTRAL Group'
            },
            {
                thumb : '03',
                details: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ex, itaque repellendus? Optio soluta quisquam eligendi magni? Excepturi delectus quos sapiente harum reprehenderit.',
                title: 'Suwapat Amphan',
                subtitle: 'CEO',
                subtitle2: 'Betagro'
            },
            {
                thumb : '04',
                details: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ex, itaque repellendus? Optio soluta quisquam eligendi magni? Excepturi delectus quos sapiente harum reprehenderit.',
                title: 'Suwapat Amphan',
                subtitle: 'CEO',
                subtitle2: 'Ajinomoto'
            }
        ];
        let DataList = Data.map((val, i) =>{
            return(
                <div className="testimonial_style--1" key={i}>
                    <div className="inner">
                        <p className="details custom-text-grey">{val.details}</p>
                        <div className="thumbnail">
                            <img src={`/assets/images/testimonial/client-${val.thumb}.png`} alt="Testimonial Images"/>
                        </div>
                        <h2 className="title">{val.title}</h2>
                        <p className="subtitle">{val.subtitle}</p>
                        <p className="subtitle2 mt-1">{val.subtitle2}</p>
                    </div>
                </div>
            )
        });
        return(
            <div className="testimonial-area sass-testimonial ptb--120 bg_color--5" id="customers">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h3 className="heading custom-text-black">Trusted Clients</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 mb--70">
                            <div className="testimonial-active-wrapper slick-default-dots">
                                <Slider {...testimonial1}>
                                    {DataList}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SassTestimonial;
