import React, { Component } from "react";
import { Helmet } from "react-helmet";

class CancellationPolicy extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>HR plus</title>
          <meta
            name="description"
            content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template."
          />
        </Helmet>

        <div className="container">
          <h1>Cancellation Policy</h1>
          <p>No cancellation options and refund will be provided for any subscriptions. We dont provide credit, refunds and you will continue to have access to your until the end of montly billing cycle </p>
          
        </div>
      </React.Fragment>
    );
  }
}
export default CancellationPolicy;
