import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { withTranslation } from "react-i18next";


class Accordion01 extends Component {
    constructor(props){
        super(props);
        this.state = {
            allowZeroExpanded: true,
            preExpanded: '1'
        };
    }
    render(){
        const {t} = this.props
        return(
            <Accordion className="accodion-style--1" value={this.state.allowZeroExpanded}>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        { t('common:FAQ.HOWLOGIN') }
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ol>
                            <li>{ t('common:FAQ.HOWLOGINANS1') }</li>
                            <li>{ t('common:FAQ.HOWLOGINANS2') }</li>
                        </ol>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        { t('common:FAQ.HOWRESET') }
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ol>
                            <li>{ t('common:FAQ.RESETANS1') }</li>
                            <li>{ t('common:FAQ.RESETANS2') }</li>
                            <li>{ t('common:FAQ.RESETANS3') }</li>
                            <li>{ t('common:FAQ.RESETANS4') }</li>

                        </ol>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        { t('common:FAQ.CHECKQUE') }
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ol>
                            <li>{ t('common:FAQ.CHECKANS1') }</li>
                            <li>{ t('common:FAQ.CHECKANS2') }</li>
                            <li>{ t('common:FAQ.CHECKANS3') }</li>
                            <li>{ t('common:FAQ.CHECKANS4') }</li>

                        </ol>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        { t('common:FAQ.LEAVEQUE') }
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ol>
                            <li>{ t('common:FAQ.LEAVEANS1') }</li>
                            <li>{ t('common:FAQ.LEAVEANS2') }</li>
                            <li>{ t('common:FAQ.LEAVEANS3') }</li>
                            <li>{ t('common:FAQ.LEAVEANS4') }</li>

                        </ol>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        { t('common:FAQ.ANNOUNCEMENTQUE') }
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ol>
                            <li>{ t('common:FAQ.ANNOUNCEMENTANS1') }</li>
                            <li>{ t('common:FAQ.ANNOUNCEMENTANS2') }</li>
                            <li>{ t('common:FAQ.ANNOUNCEMENTANS3') }</li>

                        </ol>
                    </AccordionItemPanel>
                </AccordionItem>
                
            </Accordion>
        )
    }
}

export default withTranslation()(Accordion01);