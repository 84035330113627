import React, { Component, useState } from "react";
import {Helmet} from 'react-helmet'
import Headerdefault from '../components/header/Headerdefault';
import Brand from '../components/brand/Brand';
import TabsTwo from '../elements/component/tabs/TabsTwo';
import SassTestimonial from '../components/layouts/sass/SassTestimonial';
import PricingBoxOne from '../elements/component/pricingBox/PricingBoxOne';
import CountersOne from '../elements/component/counters/CountersOne';
import Accordion01 from '../components/accordion/Accordion';
import CalltoActionThree from '../elements/component/callto-action/CalltoActionThree';
import SoftwareService from '../components/layouts/software/SoftwareService';
import ContactTwo from '../elements/component/contact/ContactTwo';
import Footer from '../components/footer/Footer';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import './hrplus.scss'
import siteConfigs from "../constants/StieConfigs";
import {useTranslation} from "react-i18next";
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { emailQuestions } from "../services/email.services";
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

function HrPlus (){
    const {t, i18n} = useTranslation('common');
    const [showMess, setShowMess] = useState(true)

    const [formData, setFormData] = useState('')
    const [emailStatus, setEmailStatus] = useState(false)
    const [visibleHeaderImg, setVisibleHeaderImg] = useState('img1')
    const [currentLang, setCurrentLang] = useState('EN')

    React.useEffect(() => {
        const _currentLang = localStorage.getItem('currentLang')
        if(_currentLang){
            i18n.changeLanguage(_currentLang);
            let langSelected = (_currentLang === 'en') ? "EN" : "TH"
            setCurrentLang(langSelected)
        }
    }, [])

    const onLangChange = (lang) => {
        setCurrentLang(lang)
    }
    

    const onChangeHandler = (e) => {
        let name = e.target.name 
        let value = e.target.value 
        setFormData({...formData, [name]: value})
    }

    const onFormSubmit = async(event) => {
        event.preventDefault();
        console.log('formData', formData)
        const res = await emailQuestions(formData);
        console.log(res)
        console.log('res status',res.status)
        if(res.status == '200'){
            console.log('res status',res.status)
            setFormData({});
            setEmailStatus(true)
            setInterval(() => {
                setEmailStatus(false)
            }, 10000);
        }
    }

    // React.useEffect(() => {
    //     window.setInterval(() => {
    //         console.log('interval change', visibleHeaderImg)
    //         setVisibleHeaderImg(prevVal => prevVal === 'img1' ? 'img2' : 'img1')
    //     }, 6000);
    // }, [])
    

    const slideImages = [
        {
          url: 'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
          caption: t('HEADER.TITLE')
        },
        // {
        //   url: 'https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80',
        //   caption: t('HEADER.TITLE')
        // },
        // {
        //   url: 'https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
        //   caption: 'Slide 3'
        // },
      ];
      

      const spanStyle = {
        padding: '20px',
        background: '#efefef',
        color: '#000000'
      }
      
      const divStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        height: '400px'
      }

      const [scrollPercentage, setScrollPercentage] = useState(0);
      React.useEffect(() => {
        const handleScroll = () => {
            console.log('handleScroll =>>')
          const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          const currentScrollPercentage = (scrollTop / scrollHeight) * 100;
          console.log('currentScrollPercentage', currentScrollPercentage)
          setScrollPercentage(currentScrollPercentage);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []); // Empty dependency array to run the effect only once

    return(
        <React.Fragment>
            <Helmet>
                <title>HR plus</title>
                <meta name="description" content="HRPlus App is a cloud-based, all-in-one HR solution that enables business owners to automate workflow processes and increase your business productivity. Hr Plus, Check-in, Check out, เช็คอิน เช็คเอาท์, ลงเวลางาน, เก็บข้อมูลในองค์กร, เก็บข้อมูลในองค์กร, เก็บข้อมูล, ลางานออนไลน์, เบิกจ่ายออนไลน์, สลิปงานเดือนออนไลน์, ปฏิทินออนไลน์" />
            </Helmet>

            {/* Start Header Area */}
            <Headerdefault onLangChange={onLangChange} currentLang={currentLang} headertransparent="header--transparent" colorblack="color--black" logoname="logo-lg.jpg" />
            {/* End Header Area */}
            {/* <div className={scrollPercentage > 1 ? "slide-container body-scroll" : "slide-container"}> */}
            <div className={"slide-container"}>
            <Fade scale={1.4} indicators={false} autoplay={true} arrows={false} pauseOnHover={false}>
            {slideImages.map((each, index) => (
                <div key={index} style={{ width: "100%" }}>
                    <div className="slider-area slider--software fullscreen" id="head" style={{marginTop: 100}}>
                <div className="slider-wrapper">
                    <div className="row align-items-center hr-main-banner">
                        <div className="col-lg-6">
                            <div className="slider-text">
                                <h2 className="title custom-text-white old-font">{each.caption} </h2>
                                <p className="description custom-text-white old-font">
                                { t('HEADER.SUBTITLE') }
                                </p>
                                <div className="donwload-btns">
                                <div>
                                <a href={siteConfigs.playStoreLink} target="_blank">
                                    <button className="download-clickable">
                                        <img src="/assets/images/hrplus/For Android.png" />
                                    </button>
                                </a>
                                </div>
                                <div>
                                    <a href={siteConfigs.appleStoreLink} target="_blank">
                                        <button className="download-clickable">
                                            <img src="/assets/images/hrplus/For iOS.png" />
                                        </button>
                                    </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="thumbnail">
                                <img src="/assets/images/hrplus/Banner1.png" alt="SLider Images"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                </div>
            ))}
        </Fade>
        <div className="scrollNavWrap onlyPc"><span className="scrollNav"><font style={{verticalAlign: 'inherit'}}><font style={{verticalAlign: 'inherit'}}>Scroll</font></font></span></div>
        </div>
            {/* <div id="mvArea">
                <ul>
                    <li>
                        <div className="li-img-container">
                            {visibleHeaderImg === 'img1' ?
                             <img src="https://line.me/static/934a804ce9643bf673711c7f1a4f2b05/1a900/mv01.jpg" />
                             :
                             <img src="https://line.me/static/955d00edaf297a2124cc88f218fe0deb/1a900/mv02.jpg" />
                             }
                        </div>
                    </li>
                </ul>
            </div> */}
            {/* Start Slider Area */}
            {/* <div className="slider-area slider--software fullscreen" id="head" style={{marginTop: 100}}>
                <div className="slider-wrapper">
                    <div className="row align-items-center hr-main-banner">
                        <div className="col-lg-6">
                            <div className="slider-text">
                                <h2 className="title custom-text-white old-font">{t('HEADER.TITLE')} </h2>
                                <p className="description custom-text-white old-font">
                                { t('HEADER.SUBTITLE') }
                                </p>
                                <div className="donwload-btns">
                                <div>
                                <a href={siteConfigs.playStoreLink} target="_blank">
                                    <button className="download-clickable">
                                        <img src="/assets/images/hrplus/For Android.png" />
                                    </button>
                                </a>
                                </div>
                                <div>
                                    <a href={siteConfigs.appleStoreLink} target="_blank">
                                        <button className="download-clickable">
                                            <img src="/assets/images/hrplus/For iOS.png" />
                                        </button>
                                    </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="thumbnail">
                                <img src="/assets/images/hrplus/Banner1.png" alt="SLider Images"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* End Slider Area */}

            {/* Start Brand Area */}
            {/* <Brand /> */}
            {/* End Brand Area */}

            {/* Start Service Area */}
            <SoftwareService />
            {/* End Service Area */}

            {/* Start tab Area */}
            <TabsTwo />
            {/* End tab Area */}

            {/* Start Pricing Table Area */}
            <div className="pricing-table-area bg-color bg-color-hr ptb--120" data-bg-color="#F6F5F9" id="pricing">
                <div className="container">
                    <div className="row mb--40">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <h2 className="fontWeight400 mb--10 custom-text-white">{ t('PRICING.TITLE') }</h2>
                                {/* <p>If you are planning on developing a product landing app or website, take a look at this <br /> beautiful-crafted and error-free WordPress theme.</p> */}
                            </div>
                        </div>
                    </div>
                    <PricingBoxOne />
                </div>
            </div>
            {/* End Pricing Table Area */}

            {/* Start Testimonial Area */}
            {/* <SassTestimonial /> */}
            {/* End Testimonial Area */}


            {/* Start Call TO Action */}
            <div className="call-to-action-wrapper">
                <CalltoActionThree />
            </div>
            {/* End Call TO Action */}

            {/* Start Accordion Area */}
            <div className="software-accordion-area bg-color ptb--120" data-bg-color="#F6F5F9" id="faq">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <h2 className="fontWeight400 mb--10 custom-text-black">{ t('FAQ.TITLE') }</h2>
                                <p>{ t('FAQ.PLEASURE') }</p>
                            </div>
                        </div>
                    </div>

                    <div className="row mt--60">
                        {/* Start Single Accordion */}
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="hr-faq-head">
                                <p>{ t('FAQ.SUBTITLE') }</p>
                            </div>
                        </div>
                        </div>
                    <div className="row mt--60">
                        {/* Start Single Accordion */}
                        <div className="col-lg-6 col-md-12 col-12 mt_md--40 mt_sm--40">
                            <div className="software-accordion">
                                <Accordion01 />
                            </div>
                        </div>
                        {/* End Single Accordion */}

                        {/* Start Single Accordion */}
                        <div className="col-lg-6 col-md-12 col-12">
                            <p className="custom-text-black">{ t('FAQ.DINTFIND') }</p>
                            <p>{ t('FAQ.SUBTEXT') }</p>
                            <form onSubmit={onFormSubmit}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="input-box my-2">
                                        <input 
                                        type="text" 
                                        placeholder="Name *"
                                        name='name'
                                        onChange={onChangeHandler}
                                        value={formData.name || ""}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="input-box my-2">
                                        <input 
                                        type="email" 
                                        placeholder="E-mail *"
                                        name='email'
                                        value={formData.email || ""}
                                        onChange={onChangeHandler}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="input-box my-2">
                                        <textarea 
                                        className="pl-3"
                                        type="text"
                                        placeholder="Question *"
                                        name='question'
                                        value={formData.question || ""}
                                        onChange={onChangeHandler}
                                        >
                                        </textarea>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="input-box my-2">
                                        <input 
                                        type="tel" 
                                        placeholder="Telephone *"
                                        name='telephone'
                                        value={formData.telephone || ""}
                                        onChange={onChangeHandler}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="blog-button mt--30 text-left">
                                        <button className="ar-button style-flat app-bg-primary" type="submit">
                                            <span className="button-text">{ t('FAQ.SENDQUE') }</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {emailStatus && 
                        <p className="mail-success">Mail sent successfully!!!</p>
                        }

                    
                            {/* <div className="software-accordion">
                                <Accordion01 />
                            </div> */}
                        </div>
                        {/* End Single Accordion */}
                    </div>
                    {/* <div className="row">
                        <div className="col-lg-12">
                            <div className="contact-title text-center mt--70">
                                <h4 className="color-3">Still have further questions? <a className="link-hover color-4" href="/">Contact us</a> </h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="thumbnail text-center mt--70">
                                <img src="/assets/images/aeroland/software-image-02.png" alt="App landing"/>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            {/* End Accordion Area */}
            {/* <ContactTwo /> */}
            <Footer />
            {/* <button onClick={() => setShowMess(true)}>Show mess</button> */}
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}

            {showMess ? 
            <MessengerCustomerChat
                pageId="115455947835268"
                appId="980594692648794"
            />
            : null}
        </React.Fragment>
    )
}
export default HrPlus;