import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class CalltoActionThree extends Component{
    render(){
        const { t } = this.props;
        return(
            <div className="call-to-action--1 variation--2 ptb--40 hr-variant--1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="inner ptb--50">
                                <div className="content">
                                    <h3 className="text-white">{ t('common:DOWNLOAD.TITLE') }</h3>
                                    <h5 className="text-white">{ t('common:DOWNLOAD.OFFER_YOUR') }</h5>
                                    {/* <a className="download-btn" href="/" style={{backgroundColor: '#fd4082'}}>
                                        <span className="custom-text-white" style={{backgroundColor: '#fd4082'}}>{ t('common:DOWNLOAD.TRY_FREE') }</span>
                                    </a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="thumbnail">
                                <img src="/assets/images/hrplus/Banner4.png" alt="App Landing" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(CalltoActionThree);