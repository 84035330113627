

export const testimonial1 = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    dots: true,
    focusOnSelect: true,
    responsive: [
        {
          breakpoint: 769,
          settings: {
            arrows: false,
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 577,
          settings: {
            arrows: false,
            slidesToShow: 1,
          }
        },
    ]

}

export const brand = {
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    draggable: true,
    dots: false,
    focusOnSelect: false,
    responsive: [
        {
          breakpoint: 769,
          settings: {
            arrows: false,
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 577,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
          }
        },
    ]
}

export const slider1 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: true,
    dots: true,
    focusOnSelect: true,
}

export const sliderdefault = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    dots: true,
    focusOnSelect: true,
    adaptiveHeight: true,
    fade: true,
}

/*========================
    Team Activation 
==========================*/


// Script Js Acivation
export const tabSlide1 = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    centerMode: true,
    focusOnSelect: true,
    vertical: true,
    verticalSwiping: true,
    centerPadding: '10px',
    responsive: [{
            breakpoint: 450,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 420,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
}

export const tabSlide2 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: true,
    fade: true,
    dots: false,
}





/*========================
    App ScreenShot Area 
=========================*/


export const SliedScreenshot = {
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    responsive: [{
      breakpoint: 769,
      settings: {
          slidesToShow: 3,
      }
    },
    {
        breakpoint: 993,
        settings: {
            slidesToShow: 3,
        }
    },
    {
        breakpoint: 420,
        settings: {
            slidesToShow: 1,
        }
    }
  ]
}

export const testimonialOne = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
}




















