import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withTranslation } from "react-i18next";

class Feature extends Component {
  render() {
      const {t} = this.props
    return (
      <Row className="mt--30">
        <>
          <Col className="mb--30 single-feature" xs="12">
            <div className="feature">
              <div className="icon">
                <img
                  src={`/assets/images/hrplus/feature-icon01.png`}
                  alt="App Landing"
                />
              </div>
              <div className="content">
                <h5 className="heading">{t("common:FEATURES.ATTENDANCE")}</h5>
                <p>{t("common:FEATURES.ATTENDANCE_CONTENT")}</p>
              </div>
            </div>
          </Col>
          <Col className="mb--30 single-feature" xs="12">
            <div className="feature">
              <div className="icon">
                <img
                  src={`/assets/images/hrplus/feature-icon02.png`}
                  alt="App Landing"
                />
              </div>
              <div className="content">
                <h5 className="heading">{t("common:FEATURES.REPORT")}</h5>
                <p>{t("common:FEATURES.REPORTCONTENT")}</p>
              </div>
            </div>
          </Col>
          <Col className="mb--30 single-feature" xs="12">
            <div className="feature">
              <div className="icon">
                <img
                  src={`/assets/images/hrplus/feature-icon03.png`}
                  alt="App Landing"
                />
              </div>
              <div className="content">
                <h5 className="heading">{t("common:FEATURES.NOTICE")}</h5>
                <p>{t("common:FEATURES.NOTICE_CONTENT")}</p>
              </div>
            </div>
          </Col>
        </>
      </Row>
    );
  }
}

export default withTranslation()(Feature);
