import { Button, Modal } from "react-bootstrap";
import React, { Component } from "react";
import { enquiryPlan } from "../../../services/enquiry.services";

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import siteConfigs from "../../../constants/StieConfigs";
import { withTranslation } from "react-i18next";


const formSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  comment: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phoneNumber: Yup.string().matches(siteConfigs.phoneRegExp, 'Phone number is not valid').required('Required'),
});

function MyVerticallyCenteredModal(props) {
    const sendRequestCall = async (formVal) => {
        formVal = {
            ...formVal,
            selectedPackage: props.selectedPackage
        }
        console.log('formVal',formVal)
        // return
       try {
           let res = await enquiryPlan(formVal)
           console.log('res', res)
           if(res.data.resultCode === 20000){
               props.onHide()
           }
        } catch (error) {
            console.log('error', error)
       }
    }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            Enquiry
        </Modal.Title>
      </Modal.Header>
     
      <Formik
       initialValues={{
         name: '',
         comment: '',
         email: '',
       }}
       validationSchema={formSchema}
       onSubmit={values => {
         // same shape as initial values
         console.log('values',values);
         sendRequestCall(values)
       }}
     >
       {({ errors, touched }) => (
           <Form>
              <Modal.Body>
             <div className="row">
            <div className="col-lg-12">
              <div className="input-box my-2">
                <Field name="name" placeholder="Name *" />
              </div>
              {errors.name && touched.name ? (
             <div className="text-danger">{errors.name}</div>
           ) : null}
            </div>
            <div className="col-lg-12">
              <div className="input-box my-2">
                <Field name="phoneNumber" placeholder="Phone Number" />
              </div>
              {errors.phoneNumber && touched.phoneNumber ? (
             <div className="text-danger">{errors.phoneNumber}</div>
           ) : null}
            </div>
            <div className="col-lg-12">
              <div className="input-box my-2">
                <Field name="email" placeholder="E-mail *" />
              </div>
              {errors.email && touched.email ? (
             <div className="text-danger">{errors.email}</div>
           ) : null}
            </div>
            <div className="col-lg-12">
              <div className="input-box my-2">
                <Field name="comment" component="textarea" placeholder="Comment"></Field>
              </div>
              {errors.comment && touched.comment ? (
             <div className="text-danger">{errors.comment}</div>
           ) : null}
            </div>
            <div className="col-lg-12">
              <div className="blog-button mt--30 text-left">
              
              </div>
            </div>
          </div>
           {/* <button type="submit">Submit</button> */}
           </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={props.onHide}>Close</Button> */}
        <button className="ar-button style-flat app-bg-primary"
                  type="submit"
                >
                  <span className="button-text">Send Request</span>
                </button>
      </Modal.Footer>
         </Form>
       )}
     </Formik>
     
    </Modal>
  );
}

function PricingBoxOne(props) {
  const {t} = props
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedPackage, setSelectedPackage] = React.useState('')

  const onPackageChoose = (packageName) => {
    window.open("http://auth.hrplusapp.com/#/auth/register", "_blank")
    setSelectedPackage(packageName)
    // setModalShow(true)
  }
  return (
    <div className="row">
      <MyVerticallyCenteredModal
        selectedPackage={selectedPackage}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      {/* Start Single Pricing Table */}
      <div className="col-lg-6 offset-xl-1 col-xl-2 col-md-6 col-sm-6 col-12 mt--30">
        <div className="pricing-table">
          <div className="pricing-header">
            <h5 className="title">S</h5>
            <div className="pricing-wrap">
              <div className="price-wrap-inner">
                <h6 className="price">2,189.00</h6>
                <h6 className="period">{ t('common:PRICING.CURRENCY') }</h6>
              </div>
            </div>
          </div>
          <div className="pricing-content">
            <ul className="pricingfeature">
              <li>1-5 { t('common:PRICING.STAFF') }</li>
              <li>11 month + free 1 month</li>
            </ul>
          </div>
          <div className="pricing-footer mt-4">
            <button
              onClick={() =>onPackageChoose('s')}
              className="ar-button style-flat button-wih-border"
              type="submit"
            >
              <span className="button-text">{ t('common:PRICING.CHOOSE') }</span>
            </button>
          </div>
        </div>
      </div>
      {/* End Single Pricing Table */}
      {/* Start Single Pricing Table */}
      <div className="col-lg-6 col-xl-2 col-md-6 col-sm-6 col-12 mt--30">
        <div className="pricing-table">
        <div className="feature-inner-mark">
            <span>Best seller</span>
          </div>
          <div className="pricing-header">
            <h5 className="title">M</h5>
            <div className="pricing-wrap">
              <div className="price-wrap-inner">
                <h6 className="price">4,389.00</h6>
                <h6 className="period">{ t('common:PRICING.CURRENCY') }</h6>
              </div>
            </div>
          </div>
          <div className="pricing-content">
            <ul className="pricingfeature">
              <li>1-10 { t('common:PRICING.STAFF') }</li>
              <li>11 month + free 1 month</li>
            </ul>
          </div>
          <div className="pricing-footer mt-4">
            <button
            onClick={() =>onPackageChoose('m')}
              className="ar-button style-flat button-wih-border"
              type="submit"
            >
              <span className="button-text">{ t('common:PRICING.CHOOSE') }</span>
            </button>
          </div>
        </div>
      </div>
      {/* End Single Pricing Table */}
      {/* Start Single Pricing Table */}
      <div className="col-lg-6 col-xl-2 col-md-6 col-sm-6 col-12 mt--30">
        <div className="pricing-table">
        <div className="feature-inner-mark">
            <span>Popular Choice</span>
          </div>
          <div className="pricing-header">
            <h5 className="title">L</h5>
            <div className="pricing-wrap">
              <div className="price-wrap-inner">
                <h6 className="price">8,798.00</h6>
                <h6 className="period">{ t('common:PRICING.CURRENCY') }</h6>
              </div>
            </div>
          </div>
          <div className="pricing-content">
            <ul className="pricingfeature">
              <li>1-20 { t('common:PRICING.STAFF') }</li>
              <li>11 month + free 1 month</li>
            </ul>
          </div>
          <div className="pricing-footer mt-4">
            <button
            onClick={() =>onPackageChoose('L')}
              className="ar-button style-flat button-wih-border"
              type="submit"
            >
              <span className="button-text">{ t('common:PRICING.CHOOSE') }</span>
            </button>
          </div>
        </div>
      </div>
      {/* End Single Pricing Table */}

      {/* Start Single Pricing Table */}
      <div className="col-lg-6 col-xl-2 col-md-6 col-sm-6 col-12 mt--30">
        <div className="pricing-table">
          {/* <div className="feature-inner-mark">
            <span>Popular Choice</span>
          </div> */}
          <div className="pricing-header">
            <h5 className="title">XL</h5>
            <div className="pricing-wrap">
              <div className="price-wrap-inner">
                <h6 className="price">21,989.00</h6>
                <h6 className="period">{ t('common:PRICING.CURRENCY') }</h6>
              </div>
            </div>
          </div>
          <div className="pricing-content">
            <ul className="pricingfeature">
              <li>1-50 { t('common:PRICING.STAFF') }</li>
              <li>11 month + free 1 month</li>
            </ul>
          </div>
          <div className="pricing-footer mt-4">
            <button
            onClick={() =>onPackageChoose('XL')}
              className="ar-button style-flat button-wih-border"
              type="submit"
            >
              <span className="button-text">{ t('common:PRICING.CHOOSE') }</span>
            </button>
          </div>
        </div>
      </div>
      {/* End Single Pricing Table */}

      {/* Start Single Pricing Table */}
      {/* <div className="col-lg-6 col-xl-2 col-md-6 col-sm-6 col-12 mt--30">
        <div className="pricing-table">
          <div className="pricing-header">
            <h5 className="title">CORP</h5>
            <div className="pricing-wrap">
              <div className="price-wrap-inner">
                <h6 className="period">{ t('common:PRICING.MORE_PALNS') }</h6>
              </div>
            </div>
          </div>
          <div className="pricing-content">
            <ul className="pricingfeature">
              <li>{ t('common:PRICING.MORE_THAN') } 50 { t('common:PRICING.STAFF') }</li>
            </ul>
          </div>
          <div className="pricing-footer">
            <button
            onClick={() =>onPackageChoose('CORP')}
              className="ar-button style-flat button-wih-border"
              type="submit"
            >
              <span className="button-text">{ t('common:PRICING.CHOOSE') }</span>
            </button>
          </div>
        </div>
      </div> */}
      
      <div className="col-lg-6 col-xl-2 col-md-6 col-sm-6 col-12 mt--30">
        <div className="pricing-table">
          <div className="pricing-header">
            <h5 className="title">XXL</h5>
            <div className="pricing-wrap">
              <div className="price-wrap-inner">
                <h6 className="price">43,989.00</h6>
                <h6 className="period">{ t('common:PRICING.CURRENCY') }</h6>
              </div>
            </div>
          </div>
          <div className="pricing-content">
            <ul className="pricingfeature">
              <li>1-100 { t('common:PRICING.STAFF') }</li>
              <li>11 month + free 1 month</li>
            </ul>
          </div>
          <div className="pricing-footer mt-4">
            <button
            onClick={() =>onPackageChoose('L')}
              className="ar-button style-flat button-wih-border"
              type="submit"
            >
              <span className="button-text">{ t('common:PRICING.CHOOSE') }</span>
            </button>
          </div>
        </div>
      </div>
      {/* End Single Pricing Table */}
    </div>
  );
}
export default withTranslation()(PricingBoxOne);
